<template>
  <v-form ref="companyCodeForm" @submit.prevent="submitCompanyCode()" class="filter">
    <v-row dense>
      <v-col cols="12" md="3">
        <v-text-field v-model="payload.users[0].companyCode" :label="$t('companyCode')" outlined dense clearable />
      </v-col>
      <v-col cols="12" md="1">
        <v-btn depressed color="#D8D8D8" :block="$vuetify.breakpoint.xsOnly" type="submit">
          {{ $t('save') }}
        </v-btn>
      </v-col>
    </v-row>
    <TLoading v-model="loading" />
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import mixinAlert from '@/mixins/mixinAlert'

export default {
  name: 'ListCompanyCodesHeaderComponent',
  mixins: [mixinAlert],
  data() {
    return {
      path: 'listItems',
      loading: false,
      payload: {
        users: [
          {
            'e-mail': '',
            companyCode: '',
          },
        ],
      },
    }
  },
  methods: {
    async submitCompanyCode() {
      let valid = this.$refs.companyCodeForm.validate()
      if (!valid) return
      let confirm = await this.mixinAlertConfirm()
      if (!confirm) return
      this.mixinAlertLoading()
      this.payload.users[0]['e-mail'] = this.getUser.email

      try {
        let result = await this.addCompanyCode(this.payload)
        await this.mixinAlertSuccess(result.data && result.data[0].message ? result.data[0].message : '')
        await this.listCompanyCodes({ email: this.getUser.email })
      } catch (error) {
        await this.mixinAlertError(error.data && error.data[0].message ? this.$i18n.t(error.data[0].message) : null)
        return
      }
    },
    ...mapActions('userStore', {
      addCompanyCode: 'setAddCompanyCode',
      listCompanyCodes: 'setCompanyCodeDataToList',
    }),
  },
  computed: {
    ...mapGetters('userStore', {
      getUser: 'getData',
    }),
  },
}
</script>
<style scoped>
.filter {
  margin-left: -100px;
}
</style>
